<template>
    <div>
        <div class="box">
            <el-page-header @back="$router.go(-1)" :content="$t('tMemberDetail')"></el-page-header>
        </div>
        <el-card class="content mt20">
            <el-form label-position="left" :model="ruleForm" ref="ruleForm" label-width="100px" class="ruleFrom row">
                <el-form-item :label="$t('memberManagement').active" prop="active" class="col-12 mb0">
                    <div>{{active(ruleForm.active)}}</div>
                </el-form-item>
            </el-form>
        </el-card>
        <h3 class="mt20 b">{{$t('memberManagement.member_information')}}</h3>
        <el-card class="content mt20">
            <el-form label-position="left" :model="ruleForm" ref="ruleForm" label-width="100px" class="row">
                <el-form-item :label="$t('memberManagement.create_time')" prop="create_time" class="col-4 mb0">
                    <div>{{ruleForm.create_time}}</div>
                </el-form-item>
                <el-form-item :label="$t('memberManagement.username')" prop="username" class="col-4 mb0">
                    <div>{{ruleForm.username}}</div>
                </el-form-item>
                <el-form-item :label="$t('memberManagement.real_name')" prop="real_name" class="col-4 mb0">
                    <div>{{ruleForm.real_name}}</div>
                </el-form-item>
                <el-form-item :label="$t('memberManagement.birth')" prop="birth" class="col-4 mb0">
                    <div>{{ruleForm.birth}}</div>
                </el-form-item>
                <el-form-item :label="$t('memberManagement.gender')" prop="gender" class="col-4 mb0">
                    <div>{{gender(ruleForm.gender)}}</div>
                </el-form-item>
                <el-form-item :label="$t('memberManagement.phone')" prop="phone" class="col-4 mb0">
                    <div>{{ruleForm.phone}}</div>
                </el-form-item>
                <el-form-item :label="$t('memberManagement.score')" prop="score" class="col-4 mb0">
                    <div>{{ruleForm.score}}</div>
                </el-form-item>
                <el-form-item :label="$t('memberManagement.email')" prop="email" class="col-4 mb0">
                    <div>{{ruleForm.email}}</div>
                </el-form-item>
                <el-form-item :label="$t('memberManagement.address')" prop="address" class="col-12 mb0">
                    <div>{{ruleForm.address}}</div>
                </el-form-item>
                <el-form-item :label="$t('memberManagement.address_detail')" prop="address_detail" class="col-12 mb0">
                    <div>{{ruleForm.address_detail}}</div>
                </el-form-item>
            </el-form>
        </el-card>
        <h3 class="mt20 b">{{$t('memberManagement.self_hobby')}}</h3>
        <el-card class="content mt20">
            <el-form label-position="left" :model="ruleForm" ref="ruleForm" label-width="100px" class="row">
                <el-form-item :label="$t('memberManagement.is_email')" label-width="150px" prop="is_email" class="col-6 mb0">
                    <div>{{yes_or_no(ruleForm.is_email)}}</div>
                </el-form-item>
                <el-form-item :label="$t('memberManagement.is_sms')" label-width="150px" prop="is_sms" class="col-6 mb0">
                    <div>{{yes_or_no(ruleForm.is_sms)}}</div>
                </el-form-item>
                <el-form-item :label="$t('memberManagement.favorite_category')" label-width="150px" prop="favorite_category" class="col-12 mb0">
                    <div>
                        <span class="v-for-span" v-for="(item,index) in ruleForm.favorite_category" :key="index">{{favorite_category(item)}}</span>
                    </div>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import { memberDetail } from '@/api/index'
export default {
    name:'memberDetail',
    data(){
        return {
            ruleForm:{
                create_time: '',
                username: '',
                real_name: '',
                gender: '',
                birth: '',
                phone: '',
                address: '',
                address_detail: '',
                favorite_category: '',
                active: ''
            }
        }
    },
    computed:{
        id(){
			return Number.isNaN(Number(this.$route.query.id)) ? undefined : Number(this.$route.query.id)
        },
    },
    methods:{
        gender(gender){
            switch(gender){
                case 0:
                    return this.$t('memberManagement.gender0')
                case 1:
                    return this.$t('memberManagement.gender1')
                case 2:
                    return this.$t('memberManagement.gender2')
            }
        },
        favorite_category(favorite_category){
            switch(favorite_category){
                    case 1:
                    return this.$t('memberManagement.favorite_category1')
                    case 2:
                    return this.$t('memberManagement.favorite_category2')
                    case 3:
                    return this.$t('memberManagement.favorite_category3')
                    case 4:
                    return this.$t('memberManagement.favorite_category4')
                    case 5:
                    return this.$t('memberManagement.favorite_category5')
                    case 6:
                    return this.$t('memberManagement.favorite_category6')
                    case 7:
                    return this.$t('memberManagement.favorite_category7')
            }
        },
        yes_or_no(yes_or_no){
            switch(yes_or_no){
                case 0:
                    return this.$t('memberManagement.no')
                case 1:
                    return this.$t('memberManagement.yes')
            }
        },
        active(active){
            switch(active){
                case 0:
                    return this.$t('memberManagement.active0')
                case 1:
                    return this.$t('memberManagement.active1')
            }
        },
        async get_info(){
            const information = await memberDetail(this.id)
            this.ruleForm = information
        }
    },
    created(){
        this.get_info()
    },
}
</script>
<style lang="scss" scoped>
.v-for-span + .v-for-span::before{
    content:'/'
}
    /deep/.el-form-item__content{
        word-wrap: break-word;
        font-weight: bold;
    }
</style>